import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import welcome from "./images/welcome.svg"

const Thankyou = (props) => {
    const { message} = props
    const [top, setTop] = useState();
    const [bottom, setBottom] = useState();
    
  

  useEffect(() => {
    if(message === 'requirement'){
      setTop('THANK YOU FOR REACHING OUT TO US')
      setBottom("Will get back to you!")
    }if(message === 'portfolio'){
      setTop('THANKS FOR JOINING')
      setBottom("Your Portfolio is successful. You've added to our list and will hear from us soon.")
    }
  },[message])
  return (
    <>
    {message ? (<>
    <br />
    <br />
    <br />
    <Box sx={{ flexGrow: 3, my: 6 }}>
     <Grid container columns={12} >

     <Grid item sm={12} xs={12} lg={2}></Grid>
     <Grid item sm={12} xs={12} lg={8} style={{justifyContent: "center", display: "flex"}}><h1 className='fntRobo fntBold'>{top ? top : ''}</h1></Grid>
     <Grid item sm={12} xs={12} lg={2}></Grid>
        
     <Grid item sm={12} xs={12} lg={2}></Grid>
     <Grid item sm={12} xs={12} lg={8} sx={{  my: 4 }} style={{justifyContent: "center", display: "flex"}}><img src={welcome}/></Grid>
     <Grid item sm={12} xs={12} lg={2}></Grid>


     <Grid item sm={12} xs={12} lg={4}></Grid>
     <Grid item sm={12} xs={12} lg={4} style={{justifyContent: "center", display: "flex"}}><h1 className='fntRobo fntBold'>{bottom ? bottom : ''}</h1></Grid>
     <Grid item sm={12} xs={12} lg={4}></Grid>
     </Grid>
     </Box>
     </>) : ''}
    </>
  )
}

export default Thankyou