import * as React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { CustomButtonGroupAsArrows } from './CustomArrows'
import { Typography } from '@mui/material'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}
const images = [
  {
    msg: 'Georgia Filming delivers work for our company, and we are satisfied with their work & Input.  They are not afraid to suggest new ideas and challenge ideas that we may have. I find them to be a great partner for handling our social media, and definitely recommend them.',
    name: 'Mrs. Smitha'
  },
  {
    msg: "Working with Georgia Filming has been an incredible experience. We hired them to produce a series of customer testimonial videos, and they did an outstanding job. The team was highly organized, efficient, and easy to work with throughout the entire process. They meticulously planned the shoot, ensured that all participants felt comfortable and at ease on camera, and captured the essence of our customers' stories flawlessly. The final videos were visually stunning, emotionally compelling, and delivered the desired impact. Georgia Filming's professionalism and commitment to excellence are evident in every aspect of their work. We wholeheartedly recommend them to anyone seeking top-quality video production services.",
    name: 'Venkata Narayana'
  },
  {
    msg: "Georgia Filming has been an invaluable partner for our organization. We have engaged their services for various projects, including event coverage, live streaming, and post-event recap videos. The team's expertise and attention to detail are truly exceptional. They seamlessly captured the energy and atmosphere of our events, ensuring that every important moment was documented beautifully. Their editing skills brought the videos to life, effectively conveying the excitement and impact of our gatherings. Working with Georgia Filming is a collaborative and stress-free experience. Their professionalism, creativity, and dedication to delivering outstanding results are second to none. We highly recommend them to anyone in need of exceptional video production services.",
    name: 'Nicolas Cage'
  },
  {
    msg: 'We have been working with Georgia Filming on a various projects including social media video interviews, event coverage, and promotional videos. Working with the Team is a true joy. Their video/audio is of the highest quality. They are available to answer questions and he delivers final results quickly and seamlessly. In addition to their video work being outstanding, The Team is personable, coachable, and easy to work with. They are professional, always on time, and goes above and beyond to make sure each project is delivered to the highest standard. I highly recommend working with Georgia Filming and their team.',
    name: 'Michael Shon'
  },
  {
    msg: 'NGR Media Tech feels like a true extension of our team, that’s how well we’ve worked together. We’ve done multiple projects with the team and in all cases, they were highly responsive. would recommend them without hesitation to any organization searching for Media production expertise.',
    name: 'Robert Eggers'
  }
]
export default function TestimonialsContent () {
  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={4000}
        centerMode={false}
        className=''
        containerClass='container-padding-bottom'
        customButtonGroup={<CustomButtonGroupAsArrows />}
        dotListClass=''
        draggable
        focusOnSelect={false}
        infinite={true}
        itemClass=''
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1,
            partialVisibilityGutter: 30
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=''
        slidesToSlide={1}
        swipeable
      >
        {images.slice(0, 7).map(image => {
          return (
            <div style={{ margin: '0px 12px 0px 12px' }}>
              <div>
                <Typography
                  variant='h6'
                  sx={{ my: 2 }}
                  style={{
                    textAlign: 'center',
                    fontSize: '14px'
                    // font: 'normal normal normal 16px/28px Playfair Display'
                  }}
                >
                  {image?.msg}
                </Typography>
                <br />
                <p
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {image?.name}
                  <hr style={{ width: '10%', margin: '0 auto' }} />
                </p>
              </div>
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
