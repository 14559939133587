import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Requirements from './requirements'

export default function Form2 (props) {
  return (
    <div>
      {/* <Button variant="outlined" onClick={props.handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog className='dilogpopup widths' open={props.open2} > 
        <DialogActions>
          <Button
            onClick={props.handleClose2}
            style={{ background: 'red', color: '#fff', width: '1%' }}
          >
            X
          </Button>
        </DialogActions>
        <Requirements />
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  )
}
