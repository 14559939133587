import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import SendIcon from '@mui/icons-material/Send'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import NearMeIcon from '@mui/icons-material/NearMe'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import SwipeableViews from 'react-swipeable-views'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import PropTypes from 'prop-types'
import './custom.css'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'

import Paper from '@mui/material/Paper'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/system'
import { TabsUnstyled } from '@mui/base'

import GalleryTabs from './components/GalleryTabs'
import MakersSlider from './components/MakersSlider'
import OurBlog from './components/OurBlog'
import MediaHouses from './components/MediaHouses'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'

import Input from '@mui/material/Input'
import PinDropIcon from '@mui/icons-material/PinDrop'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import TwitterIcon from '@mui/icons-material/Twitter'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

import Register from './components/register'
import Requirements from './components/requirements'
import Thankyou from './components/thankyou'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState, useRef } from 'react'
import { ImageListItem, TextField } from '@mui/material'
import TestimonialsContent from './components/tesimonials'
import Form1 from './components/form1'
import Form2 from './components/form2'
import AboutPopup from './components/aboutPopUp'
import GalleryPopup from './components/galleryPopup'
import ServicesPopup from './components/servicesPopup'

const clientsImg = [
  {
    img: '/images/cl1.png',
    title: 'shotting'
  },
  {
    img: '/images/cl2.png',
    title: 'production'
  },

  {
    img: '/images/cl3.png',
    title: 'production'
  },
  {
    img: '/images/cl4.png',
    title: 'studio'
  },
  {
    img: '/images/cl5.png',
    title: 'studio'
  },

  {
    img: '/images/cl6.png',
    title: 'production'
  },

  {
    img: '/images/cl7.png',
    title: 'production'
  }
]

const darkTheme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#C19B24',
      contrastText: '#ffffff'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#C19B24'
    }
  },
  typography: {
    subheading: {
      color: '#C19B24',
      font: 'normal normal normal 12px/20px Playfair Display !important'
    },
    avname: {
      font: 'normal normal 500 20px/34px Playfair Display !important'
    },
    number: {
      font: 'normal normal bold 36px/77px Josefin Sans !important',
      color: '#fff'
    },
    number_lbl: {
      font: 'normal normal 500 20px/74px Playfair Display',
      color: '#fff'
    },
    wsh: {
      color: '#fff',
      font: 'normal normal bold 21px/45px Josefin Sans !important'
    },
    wsp: {
      color: '#fff',
      font: 'normal normal normal 16px/28px Playfair Display !important'
    },
    address: {
      font: 'normal normal normal 16px/32px Josefin Sans !important'
    }
  }
})
const ariaLabel = { 'aria-label': 'description' }
const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  background: 'none',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#27292A'
  }
}))
const StyledToolbar = styled(Toolbar)({
  backgroundColor: '#27292A', //#307192
  color: '#fff'
})
const NoDivider = styled(Divider)({
  width: '58px',
  height: '1px',
  background: '#707070',
  margin: 'auto'
})
const GalTab = styled(Tab)({
  font: 'normal normal normal 16px/11px Josefin Sans !important',
  fontSize: '40px'
})

const ActBtn = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',

  fontSize: '16px',
  padding: '12px 24px',
  border: 'none',
  cursor: 'pointer'
}))
const FooterItem = styled(ListItem)({
  font: 'normal normal normal 16px/32px Playfair Display',
  paddingLeft: '0'
})

const drawerWidth = 240
const navItems = ['Home', 'About Us', 'Our Services', 'Contact']

const itemData = [
  {
    img: '../images/ban1.png',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true
  },
  {
    img: '../images/ban2.png',
    title: 'Burger',
    author: '@rollelflex_graphy726'
  }
]

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

function App () {
  const aboutRef = useRef()
  const homeRef = useRef()
  const serviceRef = useRef()
  const teamRef = useRef()
  const contactRef = useRef()
  const formRef = useRef()
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_lcikxhd',
        'template_ra4yf1l',
        form.current,
        'hHk1gJukMYaFgCsxP'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  const [open1, setOpen1] = React.useState(false)
  const [openAbout, setOpenAbout] = React.useState(false)
  const [openGallery, setOpenGallery] = React.useState(false)
  const [openServices, setOpenServices] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)
  const [headR, setHeadR] = React.useState(false)
  const [tabv, setTabv] = React.useState("ho")
  React.useEffect(() => {
    const onScroll = e => {
      if (document.body.scrollTop > 100) {
        setHeadR(true)
      } else {
        setHeadR(false)
      }
    }
    window.addEventListener('scroll', onScroll, true)
    return () => window.removeEventListener('scroll', onScroll, true)
  }, [])

  const handleClickOpen1 = () => {
    setOpen1(true)
  }

  const handleClose1 = () => {
    setOpen1(false)
  }

  const handleClickOpen2 = () => {
    setOpen2(true)
  }

  const handleClickOpenAbout = () => {
    setOpenAbout(true)
  }
  const handleClickOpenGallery = () => {
    setOpenGallery(true)
  }
  const handleCloseGallery = () => {
    setOpenGallery(false)
  }

  const handleClickOpenServices = () => {
    setOpenServices(true)
  }
  const handleCloseServices = () => {
    setOpenServices(false)
  }

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const handleClose2 = () => {
    setOpen2(false)
  }

  const ScrolToAbout = event => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ab");
  }
  const ScrolToService = event => {
    serviceRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("se");
  }

  const ScrolToTeam = event => {
    teamRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("te");
  }

  const ScrolToContact = event => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("co");
  }

  const ScrolToHome = event => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' })
    setTabv("ho");
  }

  const [isShownReq, setIsShownReq] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const handleClick = event => {
    // 👇️ toggle shown state
    // *** as the normal message

    setIsShown(current => !current)
    setIsShownReq(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }
  const handleClickReq = event => {
    // 👇️ toggle shown state

    setIsShownReq(current => !current)
    setIsShown(false)
    setMessage('')
    setTimeout(() => {
      // ***
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 100)
    // 👇️ or simply set it to true
    // setIsShown(true);
  }

  const [message, setMessage] = useState()
  const thanks = e => {
    setMessage(e)
    setIsShown(false)
    setIsShownReq(false)
  }

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [value, setValue] = React.useState(0)
  const theme = useTheme()
  const [dense, setDense] = React.useState(true)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        <img src='logo.png'></img>
      </Typography>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Home'  onClick={(e) => handleClick (1)}/>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={event => ScrolToAbout(event)}
            sx={{ textAlign: 'left' }}
          >
            <ListItemText primary='About Us' onClick={(e) => handleClick (2)}/>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Our Services' onClick={(e) => handleClick (3)}/>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'left' }}>
            <ListItemText primary='Contact Us' onClick={(e) => handleClick (4)}/>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <div className='App'>
      <HelmetProvider>
        <ThemeProvider theme={darkTheme}>
          <AppBar
            className={headR ? 'navActive' : 'navNot'}
            component='nav'
            position='static'
          >
            <Form1
              open1={open1}
              handleClickOpen1={handleClickOpen1}
              handleClose1={handleClose1}
            />
            <Form2
              open2={open2}
              handleClickOpen2={handleClickOpen2}
              handleClose2={handleClose2}
            />
            <AboutPopup
              openAbout={openAbout}
              handleClickOpenAbout={handleClickOpenAbout}
              handleCloseAbout={handleCloseAbout}
            />
            <GalleryPopup
              openGallery={openGallery}
              handleClickOpenGallery={handleClickOpenGallery}
              handleCloseGallery={handleCloseGallery}
            />
            <ServicesPopup
              openServices={openServices}
              handleClickOpenServices={handleClickOpenServices}
              handleCloseServices={handleCloseServices}
            />
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <StyledToolbar style={{ background: 'transparent' }}>
                <Box sx={{ flexGrow: 0 }}>
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    edge='start'
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      marginRight: { sm: '60px' }
                    }}
                    onClick={event => ScrolToHome(event)}
                  >
                    <img style={{ width: '120px' }} src='logo.png'></img>
                  </Typography>
                </Box>
                <Box
                  sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                  style={{ justifyContent: 'center' }}
                >
                  <Button
                    onClick={event => ScrolToHome(event)}
                    className='menuItem'
                    sx={{ mx: 1, color: '#fff', fontSize: '22px' }}
                    style={{ textTransform: 'capitalize', borderBottom: tabv == "ho" ? "1px solid #ffff" : '' }}
                  >
                    Home
                  </Button>
                  <Button
                    onClick={event => ScrolToAbout(event)}
                    className='menuItem'
                    sx={{ mx: 1, color: '#fff', fontSize: '22px' }}
                    style={{ textTransform: 'capitalize', borderBottom: tabv == "ab" ? "1px solid #ffff" : '' }}
                  >
                    About Us
                  </Button>
                  <Button
                    onClick={event => ScrolToService(event)}
                    className='menuItem'
                    sx={{ mx: 1, color: '#fff', fontSize: '22px' }}
                    style={{ textTransform: 'capitalize', borderBottom: tabv == "se" ? "1px solid #ffff" : '' }}
                  >
                    Our Services
                  </Button>
                  <Button
                    onClick={event => ScrolToTeam(event)}
                    className='menuItem'
                    sx={{ mx: 1, color: '#fff', fontSize: '22px' }}
                    style={{ textTransform: 'capitalize', borderBottom: tabv == "te" ? "1px solid #ffff" : '' }}
                  >
                    Our Team
                  </Button>
                  <Button
                    onClick={event => ScrolToContact(event)}
                    className='menuItem'
                    sx={{ mx: 1, color: '#fff', fontSize: '22px' }}
                    style={{ textTransform: 'capitalize', borderBottom: tabv == "co" ? "1px solid #ffff" : '' }}
                  >
                    Contact Us
                  </Button>
                </Box>
                <a href='tel:+16785610074'>
                  {' '}
                  <Box sx={{ flexGrow: 0 }}>
                    <Button
                      className='onlyBtn'
                      // onClick={event => ScrolToContact(event)}
                      style={{
                        color: '#000',
                        background: '#fff',
                        fontWeight: '700'
                      }}
                      size='small'
                      variant='contained'
                    >
                      Lets Talk
                    </Button>
                  </Box>
                </a>
              </StyledToolbar>
            </Container>
          </AppBar>

          <Box component='nav'>
            <Drawer
              variant='temporary'
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth
                }
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box>
            <Helmet>
              <script src='banner.js'></script>
            </Helmet>
            <div
              ref={homeRef}
              class='banner container'
              style={{ height: '750px' }}
            >
              <div class='split left'>
                <h2 style={{ color: '#fff' }}>Post Production House</h2>
                <h1 className='chkTitle' style={{ color: '#fff' }}>
                  LET’S MAKE GREAT THINGS TOGETHER
                </h1>
                <a
                  // onClick={event => handleClickReq(event)}
                  onClick={event => handleClickOpen1(event)}
                  className='button onlyBtn newButton'
                  // style={{ paddingBottom: '15px' }}
                >
                  Your Requirements
                </a>
              </div>
              <div class='split right'>
                <h2 style={{ color: '#fff' }}> Virtual Production</h2>
                <h1 className='chkTitle' style={{ color: '#fff' }}>
                  {' '}
                  FILMING TAKEN TO THE NEXT LEVEL{' '}
                </h1>
                <a
                  // onClick={event => handleClick(event)}
                  onClick={event => handleClickOpen2(event)}
                  className='button onlyBtn newButton'
                >
                  Get Connected
                </a>
              </div>
            </div>
          </Box>

          <Container ref={formRef} sx={{ maxWidth: { lg: '1400px' } }}>
            {isShown && <Register thanks={thanks} />}
            {isShownReq && <Requirements thanks={thanks} />}

            <Thankyou message={message} />
            <ToastContainer />
          </Container>

          {/* <Box
            ref={aboutRef}
            component='main'
            sx={{ p: 3 }}
            style={{ paddingTop: '100px' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs={12}>
                  <Typography variant='h2' className='title2' sx={{ mt: 4 }}>
                    ABOUT US
                  </Typography>
                  <div style={{ textAlign: 'center', fontSize: '16px' }}>
                    -----------------
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <img src='images/about-video.png'></img>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Typography
                    sx={{ my: 2 }}
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      maxWidth: '70%'
                    }}
                  >
                    Welcome to the world of Georgia Filming production, where
                    creativity meets technology to bring your ideas to life.
                    Media production encompasses a wide range of services,
                    including video production, audio production, animation,
                    Editing, and post-production. Georgia Filming is a business
                    that specialises in developing and producing many kinds of
                    media content, including movies, TV shows, documentaries,
                    commercials, music videos, and more. Our team of creative
                    experts includes a writer, a cinematographer, an Editor, and
                    a sound engineer who collaborate to make a project come to
                    life. <br></br>
                    <br></br>
                    Georgia Filming is a production company that contributes
                    significantly to the entertainment business by creating and
                    making the content that we watch and love on a daily basis.
                    Through our collaboration with NGR Media House in INDIA, a
                    business that specialises in designing and Pre & Post -
                    Production, we are able to provide social media services,
                    website design services, and content videos for marketing.
                    <br></br>
                    <br></br>
                    From pre-production scheduling and script writing through
                    post-production editing and distribution, Georgia Filming is
                    in charge of overseeing the full production process. We
                    utilise the most recent technology developments available in
                    the market, stay flexible in the face of shifting market
                    dynamics, and are capable of creating high-quality content
                    that stands out from the crowd in accordance with the tastes
                    and trends of the target audience.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box> */}

          <Box
            ref={aboutRef}
            component='main'
            sx={{ p: 2 }}
            style={{ paddingTop: '100px' }}
            
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs='2'></Grid>
                <Grid
                  item
                  xs='8'
                  style={{
                    textAlign: 'center',
                    fontSize: '24px',
                    lineHeight: '36px'
                  }}
                >
                  Welcome to the world of Georgia Filming production, where
                  creativity meets technology to bring your ideas to life. Media
                  production encompasses a wide range of services, including
                  video production, audio production, animation, Editing, and
                  post-production. Georgia Filming is a business that
                  specialises in developing and producing many kinds of media
                  content, including movies, TV shows, documentaries,
                  commercials, music videos, and more.
                </Grid>
                <Grid item xs='2'></Grid>
              </Grid>
            </Container>
          </Box>

          <Grid
            ref={aboutRef}
            container
            py={5}
            spacing={1}
            className='aboutRef'
            style={{ color: '#fff' }}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/bg-aboutout.jpg') center center no-repeat"
              }
            }}
            // style={{  }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#fff' }}
                  >
                    <p style={{ fontSize: '20px' }}>
                      Film. Entertainment. Arts. Music. Live Events. Talent.
                      Corporate. Fashion. Lifestyle.
                    </p>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        position: 'relative'
                      }}
                    >
                      {/* <NearMeIcon /> */}
                      <Button
                        onClick={e => handleClickOpenAbout(e)}
                        style={{
                          color: '#fff',
                          border: '3px solid #fff',
                          fontSize: '25px'
                        }}
                        variant='outlined'
                      >
                        Who We Are
                      </Button>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          <Box component='main' sx={{ p: 2 }} style={{ paddingTop: '100px' }}>
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs='2'></Grid>
                <Grid
                  item
                  xs='8'
                  style={{
                    textAlign: 'center',
                    fontSize: '24px',
                    lineHeight: '36px'
                  }}
                >
                  We are delighted to provide you with a comprehensive briefing
                  on the works and accomplishments of Our Media House. As a
                  leading media organization, we pride ourselves on delivering
                  high-quality content, engaging storytelling, and effective
                  communication strategies to our clients.
                </Grid>
                <Grid item xs='2'></Grid>
              </Grid>
            </Container>
          </Box>

          <Grid
            // ref={aboutRef}
            container
            py={5}
            spacing={1}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/whatwedo.png') center center no-repeat"
              }
            }}
            style={{ color: '#fff' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#fff' }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* <NearMeIcon /> */}
                      <Button
                        onClick={e => handleClickOpenGallery(e)}
                        style={{
                          color: '#fff',
                          border: '3px solid #fff',
                          fontSize: '25px'
                        }}
                        variant='outlined'
                      >
                        OUR WORK
                      </Button>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* <Box
            ref={aboutRef}
            component='main'
            sx={{ p: 2 }}
            style={{ paddingTop: '100px' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#000' }}
                  >
                    ABOUT US <br />{' '}
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>
                      -----------------
                    </div>
                  </Typography>
                </Grid>
                <Grid
                  container
                  style={{ maxWidth: '70%', alignContent: 'center' }}
                  spacing={2}
                >
                  <Grid item xs='5'>
                    <img src='images/about-video.png'></img>
                  </Grid>
                  <Grid item xs='1'>
                   
                  </Grid>
                  <Grid item xs='6'>
                    Welcome to the world of Georgia Filming production, where
                    creativity meets technology to bring your ideas to life.
                    Media production encompasses a wide range of services,
                    including video production, audio production, animation,
                    Editing, and post-production. Georgia Filming is a business
                    that specialises in developing and producing many kinds of
                    media content, including movies, TV shows, documentaries,
                    commercials, music videos, and more. Our team of creative
                    experts includes a writer, a cinematographer, an Editor, and
                    a sound engineer who collaborate to make a project come to
                    life. <br></br>
                    <br></br>
                    Georgia Filming is a production company that contributes
                    significantly to the entertainment business by creating and
                    making the content that we watch and love on a daily basis.
                    Through our collaboration with NGR Media House in INDIA, a
                    business that specialises in designing and Pre & Post -
                    Production, we are able to provide social media services,
                    website design services, and content videos for marketing.
                    <br></br>
                    <br></br>
                    From pre-production scheduling and script writing through
                    post-production editing and distribution, Georgia Filming is
                    in charge of overseeing the full production process. We
                    utilise the most recent technology developments available in
                    the market, stay flexible in the face of shifting market
                    dynamics, and are capable of creating high-quality content
                    that stands out from the crowd in accordance with the tastes
                    and trends of the target audience.
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Box> */}
          {/* <Grid
            container
            py={5}
            spacing={1}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/a-bg.png') center center no-repeat"
              }
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant='number' component='p'>
                  112
                </Typography>
                <NoDivider />
                <Typography variant='number_lbl' component='p'>
                  AWARDS
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant='number' component='p'>
                  12
                </Typography>
                <NoDivider />
                <Typography variant='number_lbl' component='p'>
                  YEARS
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant='number' component='p'>
                  450
                </Typography>
                <NoDivider />
                <Typography variant='number_lbl' component='p'>
                  COMPLETE PROJECT
                </Typography>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Item>
                <Typography variant='number' component='p'>
                  150
                </Typography>
                <NoDivider />
                <Typography variant='number_lbl' component='p'>
                  COMPLETE PROJECT
                </Typography>
              </Item>
            </Grid>
          </Grid> */}

          <Container sx={{ maxWidth: { lg: '1400px' } }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              my={8}
            >
              {/* <Grid item xs={12}>
                <Typography variant='h2' className='title2' sx={{ mt: 4 }}>
                  WHAT WE DO
                </Typography>
                <div style={{ textAlign: 'center', fontSize: '16px' }}>
                  -----------------
                </div>
              </Grid> */}

              <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <GalleryTabs />
              </Box>
            </Grid>
          </Container>

          <Grid
            ref={serviceRef}
            container
            py={5}
            className='serviceRef'
            spacing={1}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/bgservices.png') center center no-repeat"
              }
            }}
            style={{ color: '#fff' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#fff', textAlign: 'center' }}
                  >
                    {/* <h1 className='title2' style={{fontSize: "50px" }}> */}
                    Services
                    {/* </h1> */}
                    <br />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {/* <NearMeIcon /> */}

                      <Button
                        onClick={e => handleClickOpenServices(e)}
                        style={{
                          color: '#fff',
                          border: '3px solid #fff',
                          fontSize: '25px'
                        }}
                        variant='outlined'
                      >
                        What We Do
                      </Button>
                    </div>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* <Grid
            ref={serviceRef}
            container
            py={5}
            spacing={1}
            sx={{
              background: {
                xs: '#27292A',
                md: "url('images/bg-service.jpg') center center no-repeat"
              }
            }}
            style={{ color: '#fff', paddingTop: '100px' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#fff' }}
                  >
                    SERVICES <br />{' '}
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>
                      -----------------
                    </div>
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Typography
                    sx={{ my: 2 }}
                    style={{
                      fontSize: '16px',
                      textAlign: 'center',
                      maxWidth: '70%'
                    }}
                  >
                    We understand that in today's digital age, having a robust
                    online presence is crucial for any business to stay relevant
                    and competitive. Therefore, our focus is on providing
                    customised solutions that cater to the unique needs of each
                    of our clients. We understand that every project is
                    different, and we take the time to understand your goals,
                    your audience, and your brand before we start working on
                    your project.
                  </Typography>
                </Grid>

                <Grid container py={5} spacing={1}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/multimedia.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        HIRE PRODUCTION
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Corporate Videos, Ad-Films/Digital Films, Social Media
                        Videos, Feature Films, Short Films, Music Videos, Video
                        Interviews, Documentaries, Video Editing
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/film.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        ANIMATION & XR / VR
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        2D and 3D animation Infographics and motion Graphics
                        Stop motion videos. Whiteboard videos. Extended Reality
                        (XR) Services & Virtual Production.
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/record.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        RECORDING STUDIO
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Recording Studio Services, Recording, Mixing &
                        Mastering, Production & Sound Design, Corporate Event
                        Entertainment, Songwriting & Composition
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/movie.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        EQUIPMENT HIRE
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Cameras, Lenses, Monitors and recorders, Production
                        supplies, Lightning, Audio, Accessories.
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/ui-video-play.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        BRANDING STRATEGY
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Logo design, Brand messaging, Brand positioning, Brand
                        voice, Style guide, Social Media Branding.
                      </Typography>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <Item sx={{ m: 4 }}>
                      <img
                        src='/images/video-cam.png'
                        style={{ height: '48px', marginBottom: '18px' }}
                      />
                      <Typography variant='wsh' component='p'>
                        POST PRODUCTION
                      </Typography>
                      <Typography variant='wsp' component='p'>
                        Video Editing, Sound Editing, Graphic Designing, Voice
                        overs, Color Grading, Content moderation.
                      </Typography>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid> */}

          <Box component='main' sx={{ p: 2 }} style={{ paddingTop: '100px' }}>
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs='2'></Grid>
                <Grid
                  item
                  xs='8'
                  style={{
                    textAlign: 'center',
                    fontSize: '24px',
                    lineHeight: '36px'
                  }}
                >
                  <h2 className='BoldSeven'>
                    Creating an enjoyable future for visual brand engagement
                  </h2>
                  <br />
                  <p>
                    Whether you are a business owner, a marketer, a content
                    creator, or anyone looking to tell your story through media,
                    we are here to help you bring your ideas to life. Welcome to
                    the world of media production, where anything is possible.
                  </p>
                </Grid>
                <Grid item xs='2'></Grid>
              </Grid>
            </Container>
          </Box>

          <Grid
            container
            py={5}
            spacing={1}
            style={{
              background:
                "url('../images/testimonial-bg.png') center center no-repeat",
              color: '#fff'
            }}
          >
            <Container sx={{ maxWidth: { lg: '900px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h5'
                    component='div'
                    style={{ color: '#C19B24' }}
                  >
                    TESTIMONIAL
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ textAlign: 'center' }}
                  >
                    WHAT CUSTOMER’S SAYS
                  </Typography>
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src='/images/comm.png' />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  {/* <Typography
                    variant='h6'
                    sx={{ my: 2 }}
                    style={{
                      textAlign: 'center',
                      font: 'normal normal normal 16px/28px Playfair Display'
                    }}
                  >
                    We have been working with Georgia Filming on a various
                    projects including social media video interviews, event
                    coverage, and promotional videos. Working with the Team is a
                    true joy. Their video/audio is of the highest quality. They
                    are available to answer questions and he delivers final
                    results quickly and seamlessly. In addition to their video
                    work being outstanding, The Team is personable, coachable,
                    and easy to work with. They are professional, always on
                    time, and goes above and beyond to make sure each project is
                    delivered to the highest standard. I highly recommend
                    working with Georgia Filming and their team.
                  </Typography> */}
                </Grid>
              </Grid>

              <TestimonialsContent />
            </Container>
          </Grid>

          <Box component='main' sx={{ p: 2 }}>
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mb={5}
              >
                <Grid item xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    style={{ color: '#000' }}
                  >
                    OUR CLIENTS <br />{' '}
                    <div style={{ textAlign: 'center', fontSize: '16px' }}>
                      -------------------------------------
                    </div>
                  </Typography>
                  <p style={{ textAlign: 'center', fontSize: '24px' }}>
                    Who We Work WIth
                  </p>
                  <br />
                </Grid>
                <Grid container py={5} spacing={1}>
                  <Container sx={{ maxWidth: { lg: '1200px' } }}>
                    <div style={{ marginBottom: '40px' }}>
                      <MediaHouses />
                    </div>
                  </Container>
                </Grid>
                {/* <Grid
                  container
                  style={{ maxWidth: '70%', alignContent: 'center' }}
                >
                  {clientsImg.map(item => (
                    <>
                      <Grid item xs='3'>
                        <ImageListItem key={item.img}>
                          <img
                            src={`${item.img}`}
                            srcSet={`${item.img}`}
                            alt={item.title}
                            loading='lazy'
                            style={{ width: '80%' }}
                          />
                        </ImageListItem>
                      </Grid>
                    </>
                  ))}
                </Grid> */}
              </Grid>
            </Container>
          </Box>

          <Grid
            container
            spacing={1}
            style={{
              background:
                "url('../images/ourteambg.png') center center no-repeat",
              color: '#fff'
            }}
          >
            <Container
              sx={{ maxWidth: { lg: '1400px' } }}
              ref={teamRef}
              className='teamRef'
              style={{ color: '#fff' }}
              // style={{ paddingTop: '100px' }}
            >
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                mt={8}
              >
                <Grid xs={12}>
                  <Typography
                    variant='h2'
                    className='title2'
                    sx={{ mt: 4 }}
                    // style={{ color: '#000' }}
                  >
                    OUR TEAM
                  </Typography>
                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: '16px'
                      // color: '#000'
                    }}
                  >
                    -----------------
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ my: 2 }}
                    style={{
                      fontSize: '20px',
                      textAlign: 'center'
                      // color: '#000'
                    }}
                  >
                    At our Georgia Filming production service, we combine our
                    technical expertise with our creative vision to deliver
                    high-quality content that meets your unique needs. Planning
                    and Executing with the Team and fulfilling the clients
                    expectations.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mb={8} spacing={1}>
                {/* <Grid xs={12} sm={6} md={4}>
                <Item sx={{ m: 4 }} style={{ backgroundColor: '#fff' }}>
                  <img src='/images/team-main.png' style={{ width: '100%' }} />
                  <br />
                  <h4 style={{ float: 'right' }}>Nanda Reddy</h4>
                  <br />
                  <h5 style={{ float: 'right' }}>-------</h5>
                  <br />
                  <p style={{ float: 'right', fontSize: '12px' }}>
                    Director of Operations
                  </p>
                </Item>
              </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ textAlign: 'right' }}
                  justifyContent='end'
                >
                  <Item sx={{ m: 4 }} style={{ backgroundColor: '#fff' }}>
                    {/* <Typography
                    variant='avname'
                    component='div'
                    style={{ fontWeight: '600 !important', textAlign: 'right' }}
                    sx={{ mt: 4 }}
                  >
                    RAYMOND FORD
                  </Typography> */}
                    {/* <hr /> */}
                    {/* <Typography
                    variant='p'
                    component='p'
                    style={{
                      textAlign: 'right',
                      font: 'normal normal normal 20px/34px Playfair Display'
                    }}
                    sx={{ my: 1 }}
                  >
                    Film Maker
                  </Typography> */}
                    {/* <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <Avatar
                      sx={{ width: 22, height: 22 }}
                      src='/images/fb.png'
                      variant='square'
                    />
                    <Avatar
                      sx={{ width: 22, height: 22 }}
                      src='/images/tw.png'
                      variant='square'
                    />
                    <Avatar
                      sx={{ width: 22, height: 22 }}
                      src='/images/in.png'
                      variant='square'
                    />
                    <Avatar
                      sx={{ width: 22, height: 22 }}
                      src='/images/lin.png'
                      variant='square'
                    />
                  </Stack> */}
                    {/* <Typography
                    variant='p'
                    component='p'
                    sx={{ mt: 4 }}
                    style={{ textAlign: 'right' }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Voluptatem magnam eligendi assumenda animi voluptates
                    reprehenderit eum repudiandae expedita excepturi officia?
                  </Typography> */}
                    <div style={{ marginTop: '35px' }}>
                      <MakersSlider />
                    </div>
                    <br />
                  </Item>
                  <h5 style={{ textAlign: 'center', fontSize: '30px' }}>
                    We're team of professional people who loves what we do.
                  </h5>
                  <br />
                </Grid>
              </Grid>
            </Container>
          </Grid>

          {/* <Grid container py={5} spacing={1}>
            <Container sx={{ maxWidth: { lg: '1200px' } }}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                my={6}
              >
                <Grid item xs={12}>
                  <Typography variant='h2' className='title2' sx={{ mt: 4 }}>
                    OUR BLOG
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant='h6'
                    sx={{ my: 2 }}
                    style={{
                      font: 'normal normal normal 16px/28px Playfair Display',
                      textAlign: 'center'
                    }}
                  >
                    It is a long established fact that a reader will be
                    distracted readable
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <OurBlog />
                </Grid>
              </Grid>
            </Container>
          </Grid> */}

          {/* <Grid container py={5} spacing={1}>
            <Container sx={{ maxWidth: { lg: '1200px' } }}>
              <div style={{ marginBottom: '40px' }}>
                <MediaHouses />
              </div>
            </Container>
          </Grid> */}

          <Grid
            ref={contactRef}
            container
            spacing={1}
            sx={{
              background: {
                xs: '#27292A'
                // md: "url('../images/footer-bg.png') center center no-repeat"
              }
            }}
            style={{ color: '#fff' }}
          >
            <Container sx={{ maxWidth: { lg: '1400px' } }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <Item
                    sx={{ m: 4 }}
                    style={{ color: '#fff', textAlign: 'left' }}
                  >
                    <a
                      href='tel:+16785610074'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        variant='wsh'
                        component='p'
                        style={{ textAlign: 'left' }}
                      >
                        GET IN TOUCH WITH US
                      </Typography>
                    </a>
                    <Typography
                      component='p'
                      style={{
                        // font: 'normal normal normal 14px/26px Playfair Display',
                        marginBottom: '5px'
                      }}
                    >
                      If you have any questions, please drop a email with
                      relevant information, will get back to you. Thank you!
                    </Typography>
                    {/* <Input
                      sx={{ my: 1 }}
                      placeholder='Email Address'
                      inputProps={ariaLabel}
                      style={{
                        borderBottom: '1px solid #FFE',
                        width: '100%',
                        color: '#fff'
                      }}
                    />
                    <Box sx={{ my: 2 }}>
                      <Button
                        sx={{ px: 4 }}
                        style={{
                          color: '#000',
                          background: '#fff',
                          textTransform: 'initial',
                          font: 'normal normal bold 13px/20px Playfair Display'
                        }}
                        size='small'
                        variant='contained'
                      >
                        Subscribe
                      </Button>
                    </Box> */}
                    <br />
                    <Typography
                      component='p'
                      style={{
                        // font: 'normal normal normal 14px/26px Playfair Display',
                        marginBottom: '5px'
                      }}
                    >
                      We would love to work with you
                    </Typography>
                    <hr></hr>
                    <br />
                    <a
                      href='tel:+16785610074'
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        variant='address'
                        component='p'
                        style={
                          {
                            // font: 'normal normal normal 14px/26px Playfair Display'
                          }
                        }
                      >
                        <PhoneInTalkIcon
                          style={{
                            float: 'left',
                            marginRight: '8px',
                            fontSize: '20px',
                            textDecoration: 'none',
                            color: '#FFF'
                          }}
                        />{' '}
                        <span
                          style={{
                            fontSize: '23px',
                            textDecoration: 'none',
                            color: '#FFF'
                            // font: 'normal normal normal 14px/26px Playfair Display'
                          }}
                        >
                          Phone: +1 678-561-0074
                        </span>
                      </Typography>
                    </a>
                    <Typography
                      variant='address'
                      component='p'
                      style={
                        {
                          // font: 'normal normal normal 14px/26px Playfair Display'
                        }
                      }
                    >
                      <MailOutlineIcon
                        style={{ float: 'left', marginRight: '8px' }}
                      />{' '}
                      <span
                        style={{
                          // font: 'normal normal normal 14px/26px Playfair Display'
                          fontSize: '20px'
                        }}
                      >
                        Email: info@georgiafilming.com
                      </span>
                    </Typography>
                  </Item>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <form ref={form} onSubmit={sendEmail}>
                    <Item
                      sx={{ m: 4 }}
                      style={{ color: '#fff', textAlign: 'left' }}
                    >
                      <TextField
                        id='outlined-size-small'
                        size='small'
                        style={{
                          background: '#fff',
                          color: '#fff',
                          width: '100%'
                        }}
                        placeholder='First Name'
                        name='first_name'
                      />
                      <input
                        name='page_name'
                        type='hidden'
                        value='GENERAL ENQUIRY'
                      />
                      <br />
                      <br />
                      <TextField
                        id='outlined-size-small'
                        size='small'
                        style={{
                          background: '#fff',
                          color: '#fff',
                          width: '100%'
                        }}
                        placeholder='Last Name'
                        name='last_name'
                      />
                      <br />
                      <br />
                      <TextField
                        id='outlined-size-small'
                        size='small'
                        style={{
                          background: '#fff',
                          color: '#fff',
                          width: '100%'
                        }}
                        name='from_mail'
                        placeholder='Email'
                      />
                      <br />
                      <br />
                      <TextField
                        id='outlined-multiline-static'
                        multiline
                        rows={2}
                        variant='outlined'
                        style={{
                          background: '#fff',
                          color: '#fff',
                          width: '100%'
                        }}
                        name='message'
                        placeholder='Message'
                      />
                      <br />
                      <br />
                      <Button
                        className='onlyBtn'
                        style={{
                          color: '#000',
                          background: '#fff',
                          fontWeight: '700'
                        }}
                        size='small'
                        variant='contained'
                        type='submit'
                      >
                        Send
                      </Button>
                    </Item>
                    {/* <Item
                    sx={{ m: 4 }}
                    style={{ color: '#fff', textAlign: 'left' }}
                  >
                    <Typography variant='wsh' component='p'>
                      IMPORTANT LINK
                    </Typography>
                    <List dense={dense}>
                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Home' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- About Us' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Blog' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Contact' />
                        </ListItemButton>
                      </FooterItem>
                    </List>
                  </Item> */}
                  </form>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={3}>
                  <Item
                    sx={{ m: 4 }}
                    style={{ color: '#fff', textAlign: 'left' }}
                  >
                    <Typography variant='wsh' component='p'>
                      OUR SERVICES
                    </Typography>
                    <List dense={dense}>
                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Hire Studio' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Outdoor Spot' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Flexible Space' />
                        </ListItemButton>
                      </FooterItem>

                      <FooterItem>
                        <ListItemButton>
                          <ListItemText primary='- Production House Rent' />
                        </ListItemButton>
                      </FooterItem>
                    </List>
                  </Item>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  style={{ color: '#fff', textAlign: 'left' }}
                >
                  <Item
                    // sx={{ m: 4 }}
                    style={{ color: '#fff', textAlign: 'left' }}
                  >
                    {/* <Typography
                      variant='wsh'
                      component='p'
                      style={{ textAlign: 'left' }}
                    >
                      QUICK CONTACT
                    </Typography> */}
                    <img
                      src='./images/mapp.png'
                      className='cjkh'
                      style={{ width: '400px', height: '280px' }}
                    />
                    {/* <iframe
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d52877.35575517685!2d-84.32148842725623!3d34.07375054719101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5749f68c5f537%3A0xc112d56128654c4e!2sAlpharetta%2C%20GA%2C%20USA!5e0!3m2!1sen!2sin!4v1688048496475!5m2!1sen!2sin'
                      width='250'
                      height='150'
                      style={{ border: '0' }}
                      allowFullScreen=''
                      loading='lazy'
                      referrerPolicy='no-referrer-when-downgrade'
                    ></iframe> */}
                    <Typography
                      variant='address'
                      component='p'
                      style={{
                        font: 'normal normal normal 14px/26px Playfair Display',
                        marginBottom: '5px',
                        textAlign: 'center'
                      }}
                    >
                      <PinDropIcon
                        style={{ float: 'left', marginRight: '8px' }}
                      />
                      <span
                        style={
                          {
                            // font: 'normal normal normal 14px/26px Playfair Display'
                          }
                        }
                      >
                        Address: 11175 Cicero Drive, Suite 100, Alpharetta,
                        GA-30022, USA
                      </span>
                    </Typography>
                  </Item>
                </Grid>
              </Grid>
              <hr sx={{ m: 4 }} />

              <Grid container sx={{ my: 1 }} spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <Item style={{ color: '#fff', textAlign: 'left' }}>
                    Copyright ©2023 Georgia Filming. All Rights Reserved.
                  </Item>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Item
                    style={{
                      color: '#fff',
                      display: 'inline-flex',
                      float: 'right'
                    }}
                    sx={{
                      textAlign: 'right',
                      textAlign: { xs: 'center', md: 'right' }
                    }}
                  >
                    {' '}
                    <p>Powered By NGR Group</p> &emsp;
                    <FacebookIcon /> <TwitterIcon /> <InstagramIcon />{' '}
                    <LinkedInIcon />
                  </Item>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </ThemeProvider>
      </HelmetProvider>
    </div>
  )
}
export default App
