import * as React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {CustomButtonGroupAsArrows} from './CustomArrows'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const images = [
  "images/media-house-1.png",
    "images/media-house-2.png",
    "images/media-house-3.png",
    "images/media-house-4.png",
    "images/media-house-5.png",
    "images/media-house-6.png",
    "images/media-house-7.png",
    "images/media-house-8.png",
    "images/media-house-9.png",
    "images/media-house-10.png",
    
  ];
export default function MakersSlider() {

    return (
    <div>
    <Carousel
    additionalTransfrom={0}
    arrows={false} autoPlay
    autoPlaySpeed={3000} 
    centerMode={false}
    className=""
    containerClass="container-padding-bottom"
   
    dotListClass=""
    draggable
    focusOnSelect={false}
    infinite={true}
    itemClass=""
    keyBoardControl
    minimumTouchDrag={80}
    pauseOnHover
    renderArrowsWhenDisabled={false}
    renderButtonGroupOutside
    renderDotsOutside={false}
    responsive={{
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
        },
        items: 5,
        partialVisibilityGutter: 40
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0
        },
        items: 3,
        partialVisibilityGutter: 30
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464
        },
        items: 4,
        partialVisibilityGutter: 30
      }
    }}
    rewind={false}
    rewindWithAnimation={false}
    rtl={false}
    shouldResetAutoplay
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
  >
      {images.map(image => {
        return (
         <div style={{margin:'0px 12px 0px 12px'}}>
          <img 
            draggable={false}
            style={{ width: "100%", height: "100%",  }}
            src={image}
          />
          </div>
        );
      })}
    </Carousel>
    </div>
);
}