import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Register from './register';

export default function Form1(props) {
  

  return (
    <div>
      {/* <Button variant="outlined" onClick={props.handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
      className='dilogpopup widths'
        open={props.open1}
        style={{width: "100%"}}
      >
          <DialogActions>
       <Button onClick={props.handleClose1} style={{background: "red", color: "#fff", width: "1%"}}>X</Button>
        </DialogActions>
       <Register />
       <DialogActions>
        </DialogActions>
       
      </Dialog>
    </div>
  );
}