import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Register from './register'
import { Container, Grid, Typography } from '@mui/material'

export default function GalleryPopup (props) {
  return (
    <div>
      {/* <Button variant="outlined" onClick={props.handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        className='dilogpopup Ditital'
        open={props.openGallery}
        style={{ width: '100%' }}
      >
        <DialogActions style={{ position: 'relative' }}>
          <Button
            onClick={props.handleCloseGallery}
            style={{
              background: 'red',
              color: '#fff',
              width: '1%',
              position: 'absolute',
              right: '10px',
              top: '10px'
            }}
          >
            X
          </Button>
        </DialogActions>
        <Grid
          container
          py={5}
          spacing={1}
          sx={{
            background: {
              xs: '#27292A',
              md: "url('images/bg-aboutsf.png') center center no-repeat"
            }
          }}
          style={{ color: '#fff' }}
        >
          <Container sx={{ maxWidth: { lg: '1400px' } }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              my={8}
            >
              <Grid item xs={12}>
                <Typography variant='h2' className='title2' sx={{ mt: 4 }}>
                  OUR WORK
                </Typography>
                {/* <div style={{ textAlign: 'center', fontSize: '16px' }}>
                  -----------------
                </div> */}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ width: '100%' }}>
              <iframe
                src='https://ittstar1-my.sharepoint.com/personal/naveen_kumar_ittstar_com/_layouts/15/embed.aspx?UniqueId=d380a6d0-4c76-408f-bc7a-9488b4acea73&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=OneUpFileViewer&referrerScenario=EmbedDialog.Create'
                width='100%'
                height='720'
                frameborder='0'
                scrolling='no'
                allowfullscreen
                title='My_Works_NAVEEN_Updated.mp4'
                
              ></iframe>
              {/* <video autoPlay muted loop style={{ width: '100%' }}>
                <source
                  // src='./images/works.mp4'
                  src='https://ittstar1-my.sharepoint.com/:v:/g/personal/naveen_kumar_ittstar_com/EdCmgNN2TI9AvHqUiLSs6nMB2RFIjLNYETdNPpwr8bdXrg?e=sbpCIe'
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video> */}
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}
