import * as React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {CustomButtonGroupAsArrows} from './CustomArrows'
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const images = [{
  name: "Nanda Reddy",
  img: "images/team-main.png",
  des: "Director of Operations"
},{
  name: "Dan",
  img: "images/maker-3.png",
  des: "Executive Producer  (Production Head)"
},{
  name: "Naveen Kumar",
  img: "images/maker-4.png",
  des: "Line Producer, Editor"
},{
  name: "Arun",
  img: "images/maker-1.png",
  des: "Designer"
},{
  name: "Baskar",
  img: "images/maker-2.png",
  des: "Pre-production - coordinate"
}
  
  ];
export default function MakersSlider() {

    return (
    <div>
    <Carousel
    additionalTransfrom={0}
    arrows={false} autoPlay
    autoPlaySpeed={4000} 
    centerMode={false}
    className=""
    containerClass="container-padding-bottom"
    customButtonGroup={<CustomButtonGroupAsArrows />}
    dotListClass=""
    draggable
    focusOnSelect={false}
    infinite={true}
    itemClass=""
    keyBoardControl
    minimumTouchDrag={80}
    pauseOnHover
    renderArrowsWhenDisabled={false}
    renderButtonGroupOutside
    renderDotsOutside={false}
    responsive={{
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
        },
        items: 5,
        partialVisibilityGutter: 40
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0
        },
        items: 3,
        partialVisibilityGutter: 30
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464
        },
        items: 4,
        partialVisibilityGutter: 30
      }
    }}
    rewind={false}
    rewindWithAnimation={false}
    rtl={false}
    shouldResetAutoplay
    showDots={false}
    sliderClass=""
    slidesToSlide={1}
    swipeable
  >
      {images.slice(0, 7).map(image => {
        return (
         <div style={{margin:'0px 12px 0px 12px'}}>
          <img 
            draggable={false}
            style={{ width: "100%", height: "100%",  }}
            src={image?.img}
          />
          <div>
            <h4 style={{float: "right"}}>{image?.name}</h4><br />
            <h5 style={{float: "right"}}>-------</h5><br />
            <p style={{float: "right", fontSize: "12px", fontFamily: "sans-serif"}}>{image?.des}</p>
          </div>
          </div>
        );
      })}
    </Carousel>
    </div>
);
}