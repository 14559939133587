import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Register from './register'
import { Container, Grid, Typography } from '@mui/material'

export default function AboutPopup (props) {
  return (
    <div>
      {/* <Button variant="outlined" onClick={props.handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        className='dilogpopup Ditital'
        open={props.openAbout}
        style={{ width: '100%' }}
      >
        <DialogActions style={{ position: 'relative' }}>
          <Button
            onClick={props.handleCloseAbout}
            style={{
              background: 'red',
              color: '#fff',
              width: '1%',
              position: 'absolute',
              right: '10px',
              top: '10px'
            }}
          >
            X
          </Button>
        </DialogActions>
        <Grid
          container
          py={5}
          spacing={1}
          sx={{
            background: {
              xs: '#27292A',
              md: "url('images/bg-aboutsf.png') center center no-repeat"
            }
          }}
          style={{ color: '#fff' }}
        >
          <Container sx={{ maxWidth: { lg: '1400px' } }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              my={8}
            >
              <Grid item xs={12}>
                <Typography variant='h2' className='title2' sx={{ mt: 4 }}>
                  ABOUT US
                </Typography>
                {/* <div style={{ textAlign: 'center', fontSize: '16px' }}>
                  -----------------
                </div> */}
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <p
              style={{
                padding: '4% 6% 8% 6%',
                color: "#242E3D",
                // textAlign: 'center',
                fontSize: '22px',
                lineHeight: '36px'
              }}
            >
              Georgia Filming is a production company that contributes
              significantly to the entertainment business by creating and making
              the content that we watch and love on a daily basis. From
              pre-production scheduling and script writing through
              post-production editing and distribution, Georgia Filming is in
              charge of overseeing the full production process. We utilise the
              most recent technology developments available in the market, stay
              flexible in the face of shifting market dynamics, and are capable
              of creating high-quality content that stands out from the crowd in
              accordance with the tastes and trends of the target audience. <br /><br />At
              our production house, we are constantly striving towards
              revolutionizing the digital presence of our corporate clients and
              other businesses. Our vision is to empower them with a
              comprehensive suite of digital solutions that can elevate their
              brand image, expand their reach, and enhance customer engagement.
              We understand that in today's digital age, having a robust online
              presence is crucial for any business to stay relevant and
              competitive. Therefore, our focus is on providing customized
              solutions that cater to the unique needs of each of our clients.<br /><br />
              Our team of experts consists of seasoned professionals who have
              years of experience in handling digital platforms for various
              businesses across different industries. We bring a wealth of
              knowledge and expertise to the table and are committed to
              delivering exceptional results. At our production house, we place
              a strong emphasis on quality and creativity. We believe in pushing
              boundaries and coming up with innovative ideas that can help our
              clients stand out from the crowd. Overall, our vision is to be
              committed to delivering excellence and ensuring our clients'
              satisfaction with our services. <br /><br />Our team of creative experts
              includes a writer, a cinematographer, an Editor, and a sound
              engineer who collaborate to make a project come to life. Through
              our collaboration with NGR Media House in INDIA, a business that
              specializes in designing Pre & Post - Production, we are able to
              provide social media services, website design services, content
              videos for marketing, advertainment other services in India.
            </p>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  )
}
