import React, {useEffect, useState} from 'react';
import Header from './header';
import "./../page.css"
import UploadMedia from './uploadMedia';
import BasicInformation from './basicInformation';
import Thankyou from './thankyou';
import { ToastContainer, toast } from 'react-toastify';


const Register = (props) => {

  const {thanks} = props;

  const initialValues = {
first_name: '',
last_name: '',
dob: '',
gender: '',
email: '',
phone_number: '',
about: '',
street_address: '',
city: '',
state: '',
country: '',
zip_code: '',
bio_data: '',
image_portfolio: '', 
video_portfolio: '', 
bio_data_url: '',
  }
  const [selectedValue, setSelectedValue] = useState('a');
  const [formValues, setFormValues] = useState(initialValues);
  const [tabValues, setTabValues] = useState(1);
  const [source, setSource] = React.useState();
  const [countries, setCountries] = useState()
  const [states, setStates] = useState()
  const [cities, setCities] = useState()
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage]= useState();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };


  const [file, setFile] = React.useState(null)
  const [files, setFiles] = React.useState(['','',''])
    
  const fileHandler = (e) => {
      setFile(e.target.files[0])
      setFormValues({ ...formValues, bio_data: e.target.files[0] });
  }
  const fileHandlerMulti = (e, index) => {
    const list = [...files];
    list[index] = e.target.files[0];
    setFiles(list);
    
  }

  const handler = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlerVideo = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setSource();
  };

  const handlerCountry = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    getStates(value);
  };

  const handlerState = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    getCities(value);
  };

  
  
const onSubmit = (e) => {
  e.preventDefault();

  setFormErrors(validate(formValues));
    setIsSubmit(true);


  
}

const validate = (values) => {
  const errors = {};
  // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const phoneno = /^\d{10}$/;
  const pincodeno = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
  // if (!values.phone_number) {
  //   errors.phone_number = "*";
  // } else if (!phoneno.test(values.phone_number)) {
  //   errors.phone_number = "10 numbers! ony";
  // }
  // if (!values.email) {
  //   errors.email = "*";
  // } else if (!pincodeno.test(values.email)) {
  //   errors.email = "format!";
  // }


  return errors;
};

// useEffect(() => {

//   fetch('https://api.georgiafilming.com/common/countries')

// }, [])

// Function to collect data
const getApiData = async () => {
  const response = await fetch(
    "https://api.georgiafilming.com/common/countries"
  ).then((response) => response.json());

  // update the state
  setCountries(response.data);
};



useEffect(() => {
  getApiData();
}, []);


const getStates = async (e) => {
  const response = await fetch(
    `https://api.georgiafilming.com/common/countries/${e}/states`
  ).then((response) => response.json());

  // update the state
  setStates(response.data);
};


const getCities = async (e) => {
  const response = await fetch(
    `https://api.georgiafilming.com/common/states/${e}/cities`
  ).then((response) => response.json());

  // update the state
  setCities(response.data);
};

  const onTabChange = (e) => {
    setTabValues(e);
  }

  


  


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
    setFormValues({ ...formValues, video_portfolio: event.target.files[0] });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
     let formData = new FormData();
  Object.keys(formValues).forEach(index => {
    formData.append(index, formValues[index]);
  })
  var array = files;
for (var i = 0; i < array.length; i++) {
    formData.append('image_portfolio', array[i]);
}
  fetch('https://api.georgiafilming.com/georgia/', {
      method: "POST",
      body: formData
  }).then(response => {
    setMessage("requirement")
    
    
  })
  .catch(err => {
    toast.error(err.message);
  });
  
    }
  }, [formErrors]);
    
  return (
    <>
   

    {/* <Header formErrors={formErrors} onTabChange={onTabChange} tabValues={tabValues}/> */}
    {message? (<>
      <Thankyou tabValues={tabValues} message={message}/>
    </>) : (<>
      <h1  style={{textAlign:'center', marginTop:'35px', marginBottom:'35px'}}>Your Requirements</h1>
      <BasicInformation setMessage={setMessage} formErrors={formErrors} cities={cities} handlerState={handlerState} states={states} handlerCountry={handlerCountry} countries={countries} handler={handler} formValues={formValues} tabValues={tabValues} onTabChange={onTabChange} onSubmit={onSubmit}/>
    </>)}
    {/* <UploadMedia onSubmit={onSubmit} handlerVideo={handlerVideo} handleFileChange={handleFileChange} source={source} files={files} fileHandler={fileHandler} fileHandlerMulti={fileHandlerMulti}  file={file} handler={handler} formValues={formValues} selectedValue={selectedValue} onTabChange={onTabChange} tabValues={tabValues} handleChange={handleChange}/> */}
    
    <br/>
    <br /> <br/>
    <br />
    </>
  )
}

export default Register