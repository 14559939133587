import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import emailjs from '@emailjs/browser'

const BasicInformation = props => {
  const {
    tabValues,
    formErrors,
    cities,
    handlerState,
    states,
    handlerCountry,
    countries,
    onSubmit,
    onTabChange,
    handler,
    formValues,
    setMessage
  } = props

  const form = useRef()


  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_lcikxhd',
        'template_ra4yf1l',
        form.current,
        'hHk1gJukMYaFgCsxP'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      );
      e.target.reset()
      setMessage("requirement")
  }
  return (
    <>
      {tabValues == 1 ? (
        <>
          <Box sx={{ flexGrow: 1 }} className='qwerty'>
            <Grid container style={{ width: '100%' }}>
              <Grid className='basicBox fntRobo' item sm={12} xs={12} lg={12}>
                <h3
                  className='artistCenter'
                  style={{ marginBottom: '5%', marginTop: '5%' }}
                >
                  <strong style={{ fontFamily: 'sans-serif !important' }}>
                    {' '}
                    Get in touch & How we can help you!
                  </strong>{' '}
                </h3>

                <form ref={form} onSubmit={sendEmail}>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} className='goRight'>
                      <strong>First name</strong> &emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.first_name : ''}
                        name='first_name'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} className='goChk'>
                      <strong>Last Name</strong>&emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.last_name : ''}
                        name='last_name'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                  </Grid>

                  {/* <Grid container>
    <Grid item xs={12} sm={12} lg={6} className='goRight'>
    <strong>Date Of Birth</strong>&emsp;
  <input type="text" onChange={handler} value={formValues ? formValues.dob : ''} name="dob"  className='sixtyfive textChk' />
    </Grid>
    <Grid item xs={12} sm={12} lg={6} className='goChk'>
    <strong>Gender</strong>&emsp;
           <select onChange={handler} value={formValues ? formValues.gender : ''} name="gender" className='sixtyfive textChk'>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">other</option>
            </select> 
    </Grid>
   
  </Grid> */}

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} className='goRight'>
                      <strong>
                        Email
                        <span style={{ color: 'red' }}>
                          {formErrors.email ? `${formErrors.email}` : null}
                        </span>
                      </strong>
                      &emsp;
                      <input
                        className='sixtyfive textChk'
                        // value={formValues ? formValues.email : ''}
                        name='from_mail'
                        // onChange={handler}
                        placeholder='example@domain.com'
                      />
                       <input
                        className='sixtyfive textChk'
                        // value={formValues ? formValues.email : ''}
                        type='hidden'
                        name='page_name'
                        value="SUBMITED REQUIREMENTS"
                        // onChange={handler}
                        placeholder='example@domain.com'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} className='goChk'>
                      <strong>
                        Phone Number
                        <span style={{ color: 'red' }}>
                          {formErrors.phone_number
                            ? `${formErrors.phone_number}`
                            : null}
                        </span>
                      </strong>
                      &emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.phone_number : ''}
                        name='phone'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      className='goCenter thing'
                    >
                      <strong>
                        Requirement Details
                        <span style={{ color: 'red' }}>
                          {formErrors.about ? `${formErrors.about}` : null}
                        </span>
                      </strong>{' '}
                      &emsp;
                      <textarea
                        fullWidth
                        // onChange={handler}
                        // value={formValues ? formValues.about : ''}
                        name='requirements'
                        className='eightynine textChk'
                        placeholder='Describe Requirement Details here...'
                        rows={4}
                      ></textarea>
                    </Grid>
                  </Grid>

                  {/* <Grid container className='widthCh'>
    <Grid item xs={12} sm={12} lg={12} className='goCenter'>
    <h3 className='artistCenter fntRobo'><strong> <u>Full Address</u></strong> </h3>
    </Grid>
    <Grid item xs={12} sm={12} lg={12} className='goCenter'>
    <strong>Street Address<span style={{color: 'red'}}>{formErrors.street_address
                          ? `${formErrors.street_address}`
                          : null}</span></strong>&emsp;
           <input onChange={handler} value={formValues ? formValues.street_address : ''} name="street_address" className='hundred textChk'/> 
    </Grid>

    <Grid item xs={12} sm={12} lg={3} className=''>
    <br />
    <strong>Country<span style={{color: 'red'}}>{formErrors.country
                          ? `${formErrors.country}`
                          : null}</span></strong>&emsp;
           <select onChange={handlerCountry} value={formValues ? formValues.country : ''} name="country" className='widthChk textChk'>
           {countries ? (countries.map((country, idx) => (<>
         <option value={country.id}>{country.name}</option>
            </> ))
       ) : (
       <></>
       )}
            
            </select> 
    </Grid>
    <Grid item xs={12} sm={12} lg={3} className=''>   <br />
    <strong>State<span style={{color: 'red'}}>{formErrors.state
                          ? `${formErrors.state}`
                          : null}</span></strong>&emsp;
           <select onChange={handlerState} value={formValues ? formValues.state : ''} name="state" className='widthChk textChk'>
           {states ? (states.map((stat, idx) => (<>
         <option value={stat.id}>{stat.name}</option>
            </> ))
       ) : (
       <></>
       )}
       </select> 
    </Grid>
    <Grid item xs={12} sm={12} lg={3} className=''>   <br />
    <strong>City<span style={{color: 'red'}}>{formErrors.city
                          ? `${formErrors.city}`
                          : null}</span></strong>&emsp;
  <select onChange={handler} value={formValues ? formValues.city : ''} name="city" className='widthChk textChk'>
           {cities ? (cities.map((city, idx) => (<>
         <option value={city.id}>{city.name}</option>
            </> ))
       ) : (
       <></>
       )}
       </select> 
    </Grid>
    <Grid item xs={12} sm={12} lg={3} className=''>   <br />
    <strong>Zip Code</strong>&emsp;
           <input onChange={handler} value={formValues ? formValues.zip_code : ''} name="zip_code" className='widthChk textChk'/> 
    </Grid>
   
  </Grid> */}

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} className='chkCenter'>
                      <Button
                        variant='contained'
                        className='btnPrimary'
                        type='submit'
                        // onClick={onSubmit}
                        endIcon={<PlayArrowIcon />}
                      >
                        Push
                      </Button>
                    </Grid>
                  </Grid>

                  <br />
                </form>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default BasicInformation
