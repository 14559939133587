import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Checkbox from '@mui/material/Checkbox'
import { ToastContainer, toast } from 'react-toastify'
import Thankyou from './thankyou'
import emailjs from '@emailjs/browser'


const Requirements = props => {
  const { thanks } = props
  const initialValues = {
    first_name: '',
    last_name: '',
    production_house_name: '',
    type_of_production: '',
    email: '',
    phone_number: '',
    requirement: '',
    contact_by_phone: '',
    privacy_check: ''
  }
  const form = useRef()

  const [formValues, setFormValues] = useState(initialValues)
  const [isSubmit, setIsSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const [message, setMessage] = useState()
  const handler = e => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const onSubmit = e => {
    e.preventDefault()

    setFormErrors(validate(formValues))
    setIsSubmit(true)
  }

  const validate = values => {
    const errors = {}
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phoneno = /^\d{10}$/
    const pincodeno =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!values.type_of_production) {
      errors.type_of_production = '^'
    }
    if (!values.requirement) {
      errors.requirement = '^'
    }
    if (!values.phone_number) {
      errors.phone_number = '^'
    } else if (!phoneno.test(values.phone_number)) {
      errors.phone_number = '10 numbers only'
    }
    if (!values.email) {
      errors.email = '^'
    } else if (!pincodeno.test(values.email)) {
      errors.email = 'format!'
    }

    return errors
  }

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_lcikxhd',
        'template_ra4yf1l',
        form.current,
        'hHk1gJukMYaFgCsxP'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      );
      e.target.reset()
      setMessage("requirement")
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      fetch('https://api.georgiafilming.com/georgia/post-requirement', {
        method: 'POST',
        headers: myHeaders,
        mode: 'cors',
        cache: 'default',
        body: JSON.stringify(formValues)
      })
        .then(response => {
          setMessage('requirement')
          // if (response.status === 200){
          // toast.success(`${"requirements inserted"}`);
          // thanks("requirement")

          // }
        })
        .catch(err => {
          toast.error(err.message)
        })
    }
  }, [formErrors])

  return (
    <>
      {message ? (
        <>
          <Thankyou message={message} />
        </>
      ) : (
        <>
          <br />
          <br />
          <Box sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid className='basicBox fntRobo' item sm={12} xs={12} lg={12}>
                <h3 className='artistCenter fntRobo'>
                  <strong>
                    {' '}
                    Post your requirements our representative will get back to
                    you
                  </strong>{' '}
                  <hr />
                </h3>

                <form ref={form} onSubmit={sendEmail}>
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} className='goRight'>
                      <strong>First name</strong> &emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.first_name : ''}
                        name='first_name'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} className='goChk'>
                      <strong>Last Name</strong>&emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.last_name : ''}
                        name='last_name'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} className='goRight'>
                      <strong>
                        Email Address
                        <span style={{ color: 'red' }}>
                          {formErrors.email ? `${formErrors.email}` : null}
                        </span>
                      </strong>
                      &emsp;
                      <input
                        className='sixtyfive textChk'
                        // value={formValues ? formValues.email : ''}
                        name='from_mail'
                        // onChange={handler}
                        placeholder='example@domain.com'
                      />
                       <input
                        className='sixtyfive textChk'
                        // value={formValues ? formValues.email : ''}
                        type='hidden'
                        name='page_name'
                        value="GET CONNECTED"
                        // onChange={handler}
                        placeholder='example@domain.com'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} className='goChk'>
                      <strong>
                        Phone Number
                        <span style={{ color: 'red' }}>
                          {formErrors.phone_number
                            ? `${formErrors.phone_number}`
                            : null}
                        </span>
                      </strong>
                      &emsp;
                      <input
                        // onChange={handler}
                        // value={formValues ? formValues.phone_number : ''}
                        name='phone'
                        className='sixtyfive textChk'
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={6} className='goRight'>
                      <strong>
                        Type Of Production
                        <span style={{ color: 'red' }}>
                          {formErrors.type_of_production
                            ? `${formErrors.type_of_production}`
                            : null}
                        </span>
                      </strong>
                      &emsp;
                      <select
                        // onChange={handler}
                        // value={formValues ? formValues.type_of_production : ''}
                        name='production'
                        className='sixtyfive textChk'
                      >
                        <option value='Pre-Production'>Pre-Production</option>
                        {/* <option value="Production">Production</option> */}
                        <option value='Post-Production'>Post-Production</option>
                      </select>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      className='goCenter thing'
                    >
                      <strong>
                        Your Requirements
                        <span style={{ color: 'red' }}>
                          {formErrors.requirement
                            ? `${formErrors.requirement}`
                            : null}
                        </span>
                      </strong>{' '}
                      &emsp;
                      <textarea
                        // onChange={handler}
                        // value={formValues ? formValues.requirement : ''}
                        name='requirements'
                        className='eightynine textChk'
                        placeholder='Describe your Requirements here...'
                        rows={4}
                      ></textarea>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} className='chkCenter'>
                      <Button
                        variant='contained'
                        className='btnPrimary'
                        type='submit'
                        // onClick={onSubmit}
                        endIcon={<PlayArrowIcon />}
                      >
                        Push
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </form>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}

export default Requirements
